
import * as bootstrap from 'bootstrap'
import Utils from '../../../utils'
import DefaultController from "../../defaultController";
import CustomerLocationEntity from "../entity";
export default class CustomerLocationShow extends DefaultController {
    async init() {
        this.entity = "customer_locations";
        await super.init();
    }

    protected getEntityData(elem: any) {
        return CustomerLocationEntity.getEntityData(elem)
    }
    bindListeners() {
        (document.querySelector("#editLocationForm") as HTMLFormElement).addEventListener("submit", async (e) => {
            e.preventDefault();
            const elem = document.querySelector("#editLocationForm") as HTMLFormElement;
            const valid = elem.checkValidity();
            if (valid) {
                const r = await Utils.entity.upsert(this.getEntityData(elem), this.entity)
                if (r.status === 200) {
                    await this.getEntity()
                    const bsElem = bootstrap.Modal.getInstance(document.querySelector("#editLocation"))
                    bsElem.hide();
                    elem.reset();
                    this.toastr.success("Standort wurde erfolgreich aktualisiert.", "Erfolg!")
                }
            }
        });
    }
}